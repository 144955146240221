import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import VucaroLogo from '../images/vucaro.svg';
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Vucaro Ventures" />
    <div style={{ display: 'block', textAlign: 'center', margin:'auto', maxWidth: `400px`, marginTop: '5rem', marginBottom: `1.45rem` }}>
      {/* <Image /> */}
      <img src={VucaroLogo} alt="Vucaro Ventures Logo" />
      <div style={{ marginLeft: '2.5rem', marginTop: '2rem' }}>
        <a href="mailto:hello@vucaro.com">hello@vucaro.com</a>
      </div>
    </div>    
    {/* <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
